<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="logs"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("unsyncedOrders") }}</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="3">
            <v-autocomplete
              clearable
              required
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              v-model="menu_date_start"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  v-model="options.start_date"
                  :label="$t('start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.start_date"
                no-title
                color="green lighten-1"
                header-color="green lighten-1"
                @input="menu_date_start = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              v-model="menu_date_end"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  v-model="options.end_date"
                  :label="$t('end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.end_date"
                no-title
                color="premary"
                @input="menu_date_end = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn
              block
              color="primary"
              @click="filter"
              :loading="isLoadingData"
              :disabled="!canFilter"
            >
              <v-icon color="white" class="mr-2"> mdi-filter </v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import syncOrderLogService from "@/store/services/sync-order-log-service";

export default {
  components: {
    // ShowCheckout: () => import('./dialogs/ShowCheckout.vue'),
  },

  computed: {
    ...mapGetters({
      hubs: "hubs/activeHubs",
    }),

    canFilter() {
      if (
        !this.options?.hub_id ||
        !this.options?.start_date ||
        !this.options?.end_date
      ) {
        return false;
      }
      return true;
    },
  },

  watch: {
    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      isLoadingData: false,
      options: {},
      index: 0,
      logs: [],
      menu_date_start: false,
      menu_date_end: false,

      headers: [
        {
          text: "id",
          align: "start",
          value: "id",
        },

        {
          text: this.$t("hub"),
          value: "hub",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    async filter() {
      try {
        this.isLoadingData = true;
        const data =
          (await syncOrderLogService.listUnsyncedOrders(this.options)).logs ??
          {};
        this.logs = Object.values(data).map((item) => ({
          id: item,
          hub: this.hubs.find((hub) => hub.id === this.options.hub_id)?.name,
        }));
        this.isLoadingData = false;
      } catch (error) {
        this.isLoadingData = false;
      }
    },
  },
};
</script>
